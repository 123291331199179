<template>
  <div class="cityLocation">
    <breadcrumb name="城市区位"></breadcrumb>
    <div class="areaMap">
      <!-- <block-layer :cname="cname" :polygondata="polygondata"></block-layer> -->
      <leaflet-block-layer :cname="cname" :polygondata="polygondata" ></leaflet-block-layer>
    </div>
    <div class="swipercontainer">
      <down :imglist="imglist"></down>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '../components/common/Breadcrumb.vue'
import CityList from '../components/cityreserch/CityList.vue'
// import BlockLayer from '@/components/bmap/blockLayer.vue'
import leafletBlockLayer from '@/components/leaflet/leafletBlockLayer.vue'
import Down from '@/components/swiper/down.vue'
import { cityLocationImg, polygonData } from "../api/cityresearch"
// import VueBMap from 'vue-mapvgl'
export default {
  components: { Breadcrumb, CityList, leafletBlockLayer, Down },
  data() {
    return {
      imglist: [],
      polygondata: []
    }
  },
  computed: {
    cname: {
      get() {
        let a = this.$store.state.cname
        // console.log('a',  this.$store.state.cname,'aend')
        // let a = "苏州市"

        return a
      },
      set(v) {
        this.$store.commit('changeCname', v)
      }
    },
    //当前选择的id
    block: {
      get() {
        return this.$store.state.block
      },
      set(v) {
        this.$store.commit('changeBlock', v)
      }
    }
  },
  watch: {
    block(newv) {
      this.cname = this.$store.state.cname
      this.block = this.$store.state.block
      this.getImgList()
      this.getPolygonData()
    },

  },
  mounted() {
    this.cname = this.$store.state.cname
    this.block = this.$store.state.block
    this.getImgList()
    this.getPolygonData()
  },
  methods: {
    getPolygonData() {
      if (this.block == null) {
        this.$confirm('您需要选择一个板块?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
      } else {
        const params = {
          city_plate_id: this.block,
          type: 10
        }

        // this.polygondata =[]
        polygonData(params).then(res => {
          const polygondata = res.data
          this.polygondata = polygondata
        })
      }

    },





    selectedCid(cid) {
      this.$store.state.currentCity.filter(item => {
        if (item.id === cid) {
          this.$store.commit('changeCname', item.label)
        }
      })
    },

    getImgList() {
      cityLocationImg({ city_plate_id: this.block }).then(res => {
        console.log('imglist', res.data)
        this.imglist = res.data
      })
      // this.imglist = [];

    }

  }
}
</script>
<style lang="scss">
.cityLocation {
  width: 100%;
  min-height: calc(100vh - 100px);
  color: #333;
}

.areaMap {
  width: 98%;
  margin: 1%;
  min-height: calc(100vh - 184px);
  background: #ddd;
}

.swipercontainer {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  top: -100%;
  z-index: 10;
}
</style>